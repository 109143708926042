const isNotEmpty = (value, error) => {
    return value.length > 0 ? true : error ? false : null;
};

const isEmail = (value, error) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
        ? true
        : error
        ? false
        : null;
};

const isValidPhone = (value, error) => {
    return value.length === 14 ? true : error ? false : null;
};
const isValidPasswordLength = (value, error) => {
    return value.length >= 6 ? true : error ? false : null;
};

const isPasswordConfirmEqualToPassword = (password, passwordConfirm, error) => {
    return password === passwordConfirm ? true : error ? false : null;
};

const isValidWebsite = (value, error) => {
    if (value.length > 0) {
        return value.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        ) !== null
            ? true
            : error
            ? false
            : null;
    }
    return true;
};

const isDropdownSelected = (value, error) => {
    return value !== null ? true : error ? false : null;
};

export default {
    isNotEmpty,
    isEmail,
    isValidPhone,
    isValidWebsite,
    isValidPasswordLength,
    isPasswordConfirmEqualToPassword,
    isDropdownSelected
};
